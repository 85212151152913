import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-17.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import sg1 from "../../assets/images/sp/17/sg-1.jpg";
import sg2 from "../../assets/images/sp/17/sg-2.jpg";
import sg3 from "../../assets/images/sp/17/sg-3.jpg";
import sg4 from "../../assets/images/sp/17/sg-4.jpg";
import sg5 from "../../assets/images/sp/17/sg-5.jpg";
import sg6 from "../../assets/images/sp/17/sg-6.jpg";
import sg7 from "../../assets/images/sp/17/sg-7.jpg";
import sg8 from "../../assets/images/sp/17/sg-8.jpg";
import sg9 from "../../assets/images/sp/17/sg-9.jpg";
import sg10 from "../../assets/images/sp/17/sg-10.jpg";
import sg11 from "../../assets/images/sp/17/sg-11.png";
import sg12 from "../../assets/images/sp/17/sg-12.png";
import sg13 from "../../assets/images/sp/17/sg-13.png";
import sg14 from "../../assets/images/sp/17/sg-14.png";
import sg15 from "../../assets/images/sp/17/sg-15.png";
import sg16 from "../../assets/images/sp/17/sg-16.png";
import sg17 from "../../assets/images/sp/17/sg-17.png";
import sg18 from "../../assets/images/sp/17/sg-18.png";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Smart Gorla Services | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 17</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.smartgorla.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24}} lg={{span: 16}} className="text-justify mb-4">
                                <h6 className="text-primary text-uppercase fw-bold">Un partner strategico per aziende e
                                    privati</h6>
                                <h1>Smart Gorla Services SA</h1>
                                <p>Smart Gorla Services SA nasce nella Svizzera italiana per volontà di un team di
                                    imprenditori e manager con grande esperienza nel settore del facility management,
                                    proponendosi quale partner strategico di riferimento per privati e aziende alla
                                    ricerca di un servizio di qualità, affidabile, flessibile e completo.</p>
                                <p>Oggi, alla sede storica di Manno nel Canton Ticino, si affiancano le succursali di
                                    Zurigo, Vernier (Canton Ginevra) e Crissier (Canton Vaud), concretizzando la volontà
                                    di una presenza attiva, continua e dinamica sull’intero territorio Svizzero.</p>
                                <p>Nell’ottica di offrire le migliori performance in ogni settore di competenza, Smart
                                    Gorla Services si divide in due rami distinti:</p>
                                <h3>Facility Management</h3>
                                <h5>Rivolto ad aziende, enti, associazioni e organizzazioni pubbliche e private</h5>
                                <Divider/>
                                <h3>Property Services</h3>
                                <h5>Dedicato ai privati</h5>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 8}} className="text-center">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={sg1} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg2} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                                <h6><b>Dario Gabella</b> (Direttore Commerciale) e <b>Pierfranco Gabella</b> (Direttore
                                    Generale)</h6>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container text-center">
                        <Row gutter={16} align="top">
                            <Col xs={{span: 24}} lg={{span: 8}} className="text-center mb-4 mb-lg-0">
                                <div className="youtubeContainer">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/T4EkSkYWqww"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen/>
                                </div>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 8}} className="text-center mb-4 mb-lg-0">
                                <div className="youtubeContainer">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fi78-gRTi8A"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen/>
                                </div>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 8}} className="text-center mb-4 mb-lg-0">
                                <div className="youtubeContainer">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Lavv_RRHP8E"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="pb-3">
                    <div className="container">
                        <Row align="top">
                            <Col xs={{span: 24}} lg={{span: 24}} className="text-center mb-4">
                                <h3>Facility Management</h3>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg11} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg12} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg13} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg14} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                        </Row>
                        <Row align="top">
                            <Col xs={{span: 24}} lg={{span: 24}} className="text-center mb-4">
                                <h3>Servizi per privati</h3>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg15} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg16} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg17} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 12}} xl={{span: 6}} className="text-center mb-4">
                                <img src={sg18} alt="SmartGorla"
                                     className="sp-img d-inline-block align-top mb-4"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 32}} align="top">
                            <Col xs={{span: 24}} lg={{span: 17}} className="text-center mb-4">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={sg3} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg4} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg5} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg6} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg7} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 7}} className="text-center mb-4">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={sg8} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg9} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={sg10} alt="SmartGorla"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
